import Errors from '@/shared/error/errors';
import { FormsService } from "@/modules/forms/forms-service";
import { AncpiService } from "@/shared/services/ancpi.service";
import { ControlService } from "@/modules/control/control-service";
import { ControlTypeService } from "@/modules/control-type/control-type-service";

// action types
export const DO_CREATE = "create";
export const DO_UPDATE = "update";
export const DO_FIND = "find";
export const DO_FIND_FORM = "findForm";
export const DO_SEARCH_PARCEL = "searchParcel";
export const DO_SET_REALTY = "doSetRealty";
export const DO_FIND_CONTROL_TYPE = "findControlType";
export const DO_UPLOAD = "upload";
export const DO_INIT_STATE = "initFormState";
export const DO_GENERATE_DOCUMENTS = "generateDocuments";
export const CHECK_GENERATION_STATUS = "checkGenerationStatus";
export const DO_FILTER_DOCS = "filterDocs";
export const DO_CLOSE_CONTROL = "closeControl";
export const DO_FIND_COMPLAIN = "findComplain";
export const DO_SET_SELECTED_COMPLAINS = "selectComplains";
export const DO_PREVIEW_DOCUMENT = "previewDocument";
export const CHECK_PREVIEW_STATUS = "checkPreviewStatus";

// mutation types
export const FIND_SUCCESS = "findSuccess";
export const FIND_DOCS_SUCCESS = "fetchSuccess";
export const SET_FILTERED_DOCS = "setFilteredDocs";
export const RESET_FILTERED_DOCS = "resetFilteredDocs";
export const RESET = "reset";
export const SET_PARCEL = "setParcel";
export const SET_REALTY = "setRealty";
export const SET_UPLOAD_TOKEN = "setUploadToken";
export const SET_FILE_TOKEN = "setFileToken";
export const INIT_STATE = "initState";
export const SET_CURRENT_PAGE = 'setCurrentPage';
export const SET_FILTERS = 'setFilters';
export const SET_CURRENT_SELECTED_COMPLAINS = 'setCurrentSelectedComplains';
export const DO_ADD_CONTROL_PERSON = 'doAddControlPerson';
export const DO_FIND_CONTROL_PERSON = 'doFindControlPerson';
export const DO_UPDATE_CONTROL_PERSON = 'doUpdateControlPerson';

const getDefaultState = () => {
    return {
        record: null,
        parcels: [],
        realty: [],
        uploadToken: null,
        fileToken: null,
        docs: [],
        filteredDocs: [],
        totalDocs: 0,
        totalFilteredDocs: 0,
        storeFilters: {},
        currentPage: 1,
        currentComplains: []
    };
};

const state = getDefaultState();

const getters = {
    record: (state) => state.record,
    parcels: (state) => state.parcels,
    realty: (state) => state.realty,
    uploadAccessToken: (state) => state.uploadToken,
    fileToken: (state) => state.fileToken,
    totalDocs: (state) => state.totalDocs,
    docs: (state) => state.docs,
    filteredDocs: (state) => state.filteredDocs,
    totalFilteredDocs: (state) => state.totalFilteredDocs,
    filters: (state) => state.storeFilters,
    currentPage: (state) => state.currentPage,
    canGenerateDocuments: (state) => state.record?.can_generate_documents,
    canCloseControl: (state) => state.record?.can_close_control,
    currentComplains: (state) => state.currentComplains,
    canPreviewDocument: (state) => state.record?.can_preview_document
};

const actions = {
    [DO_CREATE](context, values) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "control/create", { root: true});
            ControlService.create(values)
                .then(({ data, headers }) => {
                    const uploadAccessToken = headers["x-file-upload-access-token"];

                    context.commit(SET_UPLOAD_TOKEN, uploadAccessToken);

                    context.commit(FIND_SUCCESS, data);
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit("shared/deactivateLoading", "control/create", { root: true });
                });
        });
    },
    [DO_UPDATE](context, { id, values }) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "control/update", { root: true });

            ControlService.update(id, values)
                .then((response) => {
                    if (response.headers["x-file-download-access-token"]) {
                        context.commit(SET_FILE_TOKEN, response.headers["x-file-download-access-token"]);

                        context.commit(FIND_DOCS_SUCCESS, {
                            docs: response.data?.combined_files || [],
                            totalDocs: response.data?.combined_files?.length || 0,
                        });
                    }

                    if (response.headers["x-file-upload-access-token"]) {
                        context.commit(SET_UPLOAD_TOKEN, response.headers["x-file-upload-access-token"]);
                    }

                    context.commit(FIND_SUCCESS, response.data);

                    resolve(response.data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit("shared/deactivateLoading", "control/update", { root: true });
                });
        });
    },
    [DO_FIND](context, id) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "control/find", { root: true });

            ControlService.find(id)
                .then((data) => {
                    if (data.headers["x-file-download-access-token"]) {
                        context.commit(SET_FILE_TOKEN, data.headers["x-file-download-access-token"]
                        );

                        context.commit(FIND_DOCS_SUCCESS, {
                            docs: data.data?.combined_files || [],
                            totalDocs: data.data?.combined_files?.length,
                        });
                    }

                    if (data.headers["x-file-upload-access-token"]) {
                        context.commit(SET_UPLOAD_TOKEN, data.headers["x-file-upload-access-token"]);
                    }

                    context.commit(FIND_SUCCESS, data.data);
                    resolve(data.data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit("shared/deactivateLoading", "control/find", { root: true });
                });
        });
    },
    [DO_GENERATE_DOCUMENTS](context, id) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "control/generateDocuments", { root: true });

            ControlService.generateDocuments(id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit("shared/deactivateLoading", "control/generateDocuments", { root: true });
                });
        })
    },
    async [CHECK_GENERATION_STATUS](context, id) {
        context.commit(
            "shared/activateLoading",
            "control/checkGenerationStatus",
            { root: true }
        );
        try {
            const data = await ControlService.checkGenerationStatus(id);
            return data;
        } catch (error) {
            Errors.handle(error);
        } finally {
            context.commit(
                "shared/deactivateLoading",
                "control/checkGenerationStatus",
                { root: true }
            );
        }
    },
    [DO_PREVIEW_DOCUMENT](context, id) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "control/previewDocument", { root: true });

            ControlService.previewDocument(id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit("shared/deactivateLoading", "control/previewDocument", { root: true });
                });
        })
    },
    async [CHECK_PREVIEW_STATUS](context, id) {
        context.commit("shared/activateLoading", "control/checkPreviewStatus", { root: true });

        try {
            const data = await ControlService.checkPreviewStatus(id);
            return data;
        } catch (error) {
            Errors.handle(error);
        } finally {
            context.commit("shared/deactivateLoading", "control/checkPreviewStatus", { root: true } );
        }
    },
    [DO_FIND_FORM](context, id) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "control/findForm", { root: true });
            FormsService.find(id).then(({ data }) => {
                resolve(data);
            }).catch((error) => {
                Errors.handle(error);
                reject();
            }).finally(() => {
                context.commit("shared/deactivateLoading", "control/findForm", { root: true });
            });
        });
    },
    async [DO_SEARCH_PARCEL](context, payload) {
        context.commit("shared/activateLoading", "applications/searchParcel", { root: true });
        try {
            const parcel = await AncpiService.searchParcel(payload);
            if (parcel?.data?.area) {
                context.commit(SET_PARCEL, parcel.data);
            }

            return parcel;
        } catch (error) {
            Errors.handle(error);
        } finally {
            context.commit("shared/deactivateLoading", "applications/searchParcel", { root: true });
        }
    },
    [DO_SET_REALTY]({ commit }, payload) {
        commit(SET_REALTY, payload);
    },
    [DO_FIND_CONTROL_TYPE](context, id) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "control/findControlType", { root: true });
            ControlTypeService.find(id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit("shared/deactivateLoading", "control/findControlType", { root: true });
                });
        });
    },
    [DO_UPLOAD](context, payload) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "control/upload", { root: true });
            const { uploadAccessToken, files } = payload;
            ControlService.upload(uploadAccessToken, files)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject(error);
                })
                .finally(() => {
                    context.commit("shared/deactivateLoading", "control/upload", { root: true });
                });
        });
    },
    [DO_INIT_STATE](context) {
        context.commit(INIT_STATE);
    },
    [DO_FILTER_DOCS](context, params) {
        const docs = context.getters.docs;
        const { filterFn } = params;
        if (filterFn) {
            const filteredDocs = docs.filter(filterFn);
            context.commit(SET_FILTERED_DOCS, {
                docs: filteredDocs,
                totalDocs: docs?.length,
            });
        } else {
            context.commit(RESET_FILTERED_DOCS);
        }
    },
    [DO_FIND_COMPLAIN](context, payload) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "control/findComplain", { root: true });
            ControlService.findComplains(payload)
                .then(({data}) => resolve(data))
                .catch((error) => {
                    Errors.handle(error);
                    reject(error);
                })
                .finally(() => {
                    context.commit("shared/deactivateLoading", "control/findComplain", { root: true });
                });
        })
    },
    [DO_SET_SELECTED_COMPLAINS](context, data) {
        context.commit(SET_CURRENT_SELECTED_COMPLAINS, data);
    },
    [DO_CLOSE_CONTROL](context, id) {
        return new Promise((resolve, reject) => {
            context.commit("shared/activateLoading", "control/closeControl", { root: true });

            ControlService.closeControl(id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit("shared/deactivateLoading", "control/closeControl", { root: true });
                });
        })
    },
    [DO_ADD_CONTROL_PERSON](context, data) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'control/addControlPerson', { root: true });
            ControlService.addControlPerson(data)
                .then(( data ) => {
                    resolve(data);
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'control/addControlPerson', { root: true });
                });
        });
    },
    [DO_FIND_CONTROL_PERSON](context, id) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'control/doFindControlPerson', { root: true });
            ControlService.findControlPerson(id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'control/doFindControlPerson', { root: true });
                });
        });
    },
    [DO_UPDATE_CONTROL_PERSON](context, data) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'control/updateControlPerson', { root: true });
            ControlService.updateControlPerson(data)
                .then(( data ) => {
                    resolve(data);
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'control/updateControlPerson', { root: true });
                });
        });
    },
}

const mutations = {
    [FIND_SUCCESS](state, data) {
        state.record = data;
        state.currentComplains = data.related_complains || [];
    },
    [FIND_DOCS_SUCCESS](state, payload) {
        state.docs = payload.docs;
        state.totalDocs = payload.totalDocs;
        state.filteredDocs = payload.docs;
        state.totalFilteredDocs = payload.totalDocs;
    },
    [SET_FILTERED_DOCS](state, payload) {
        state.filteredDocs = payload.docs;
        state.totalFilteredDocs = payload.totalDocs;
    },
    [RESET_FILTERED_DOCS](state) {
        state.filteredDocs = state.docs;
        state.totalFilteredDocs = state.totalDocs;
    },
    [RESET](state) {
        state.record = null;
    },
    [SET_PARCEL](state, data) {
        const parcel = state.parcels.find(
            (el) => el.topo_number === data.topo_number
        );
        if (!parcel) {
            state.parcels.push(data);
        }
    },
    [SET_REALTY](state, realties) {
        state.realty = realties;
    },
    [SET_UPLOAD_TOKEN](state, token) {
        state.uploadToken = token;
    },
    [SET_FILE_TOKEN](state, token) {
        state.fileToken = token;
    },
    [INIT_STATE](state) {
        Object.assign(state, getDefaultState());
    },
    [SET_CURRENT_PAGE](state, page) {
        state.currentPage = page;
    },
    [SET_FILTERS](state, { key, value }) {
        state.storeFilters[key] = value;
    },
    [SET_CURRENT_SELECTED_COMPLAINS](state, complains) {
        state.currentComplains = complains;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
