import {CONTROLS} from '@/core/mock/mock-data/mock-controls';
import ApiService from '@/shared/services/api.service';

export class ControlService {
    static list(params) {
        return new Promise((resolve, reject) => {
            ApiService.query('/controls', params)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static create(data) {
        return new Promise((resolve, reject) => {
            ApiService.post( '/controls?echo=true', { ...data } )
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => reject(error));
        });
    }

    static update(id, data) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/controls/${id}`, { ...data })
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }

    static find(id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/controls/${id}`)
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }

    static closeControl(id) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/controls/${id}/close`)
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        })
    }

    static generateDocuments(id) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/controls/${id}/generate-documents`)
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        })
    }

    static checkGenerationStatus(id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/controls/${id}/check-generation-status`)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        })
    }

    static previewDocument(id) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/controls/${id}/preview-document`)
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        })
    }

    static checkPreviewStatus(id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/controls/${id}/check-preview-status`)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        })
    }

    static upload(token, files) {
        return new Promise((resolve, reject) => {
           ApiService.post('/files?echo=true', files, {
               'content-type': 'multipart/form-data',
               'x-file-access-token': token,
           })
               .then((response) => resolve(response.data))
               .catch((error) => reject(error));
        });
    }

    static getSystemMetadata() {
        return new Promise((resolve, reject) => {
            ApiService.get('/controls/control-metadata')
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => reject(error));
        });
    }

    static getActivities(id) {
        return new Promise((resolve, reject) => {
            ApiService.query(`user-messages/identification/${id}`, {
                params: {
                    sort: '-created_date',
                    limit: 100
                }
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => reject(error));
        });
    }

    static findComplains(params) {
        return new Promise((resolve, reject) => {
            ApiService.query(`staff-complains/all`, params)
                .then(({data}) => resolve(data))
                .catch((error) => reject(error));
        });
    }

    static getControlsUsersList() {
        const params = { 
            params: { 
                group: ['assigned_staff_user_name', 'assigned_staff_user_id'],
                sort: 'assigned_staff_user_name',
                envelope: false,
                limit: 100,
            },
        };
        return new Promise((resolve, reject) => {
            ApiService.query('/controls', params)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static addControlPerson(data) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/control-persons?echo=true`, data)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static updateControlPerson(data) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/control-persons/${data.id}`, data)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static findControlPerson(id){
        return new Promise((resolve, reject) => {
            ApiService.query(`/control-persons/${id}`)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => reject(error));
        });
    }
}
