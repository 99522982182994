import Errors from '@/shared/error/errors';
import { ControlService } from '../control-service';

// action types
export const GET_ACTIVITIES = 'getActivities';

// mutation types
export const SET_ACTIVITIES = 'setActivities';

const state = {
    activities: [],
};

const getters = {
    activities: (state) => state.activities,
};

const actions = {
    [GET_ACTIVITIES](context, id) {
        context.commit('shared/activateLoading', 'activities/get', { root: true });
        return new Promise((resolve, reject) => {
            ControlService.getActivities(id)
                .then(({ data }) => {
                    context.commit(SET_ACTIVITIES, data);
                    resolve(data);
                })
                .catch(({ error }) => {
                    Errors.handle(error);
                    reject(error);
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'activities/get', { root: true });
                })
        });
    },
};

const mutations = {
    [SET_ACTIVITIES](state, payload) {
        state.activities = payload;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
